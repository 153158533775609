import Model from '~/models/Model'

export default class Job extends Model {
  constructor (id) {
    super(id, {
      title: '',
      intro: '',
      job_type: '',
      description: '',
      skill: [],
      remuneration: '',
      price_min: 0.00,
      price_max: 0.00,
      price: 0.00
    })
  }

  static get resource () {
    return 'jobs'
  }
}
